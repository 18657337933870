<template>
  <div class="card table-card">
    <b-card-body class="pt-1">
      <b-col cols="12">
        <div class="d-block d-md-flex justify-content-between align-items-center">
          <div class="mb-2 m-md-0">
            <h2 class="title" >Clients <b-badge class="ml-1" pill v-if="clientData.length >0 "> {{ clientData.length }} users</b-badge></h2>
          </div>
          <div class="d-block d-sm-flex align-items-center">
            <b-button class="profile-btn mb-2 my-sm-0" variant="primary" v-b-modal.bv-modal-example>
              <feather-icon class="mr-1" icon="PlusIcon" fill="#fff" stroke="#fff" size="18"/> Add Client
            </b-button>
          </div>
        </div>
      </b-col>
    </b-card-body>

    <b-table
      sticky-header
      responsive
      class="position-relative b-table-sticky-table"
      :items="clientData"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      sort-direction="asc"
      show-empty
      @sort-changed="sortChanged"
      :per-page="parPage"
      :current-page="currentPage"
      :filter="filter"
      :filter-included-fields="['email', 'phone', 'firstName', 'lastName']"
    >
      <!-- Loader -->
      <template #table-busy>
        <div class="position-relative load-data py-2">
          <!-- <loader /> -->
        </div>
      </template>

      <!-- empty data -->
      <template #empty>
        <p class="text-center pt-1">No users found.</p>
      </template>

      <!-- Column: createdAt -->
      <template #cell(createAt)="data">
        {{ getDate(data.item.createAt) }}
      </template>

      <!-- column :email -->
      <template #cell(email)="data">
        <!-- <a :href="`mailto:${data.item.email}`"> -->
          <feather-icon
            icon="MailIcon"
            stroke="#fff"
            fill="#25C348"
            size="15"
          />
          {{ data.item.email }}
          <!-- </a> -->
      </template>

      <!-- column :phone_number -->
      <template #cell(phoneFull)="data">
        <span v-if="data.item.phoneFull">
          <!-- <a :href="`tel:${data.item.phoneFull}`"> -->
            <feather-icon
              icon="PhoneIcon"
              stroke="#fff"
              fill="#25C348"
              size="15"
            />
            {{ data.item.phoneFull }}
          <!-- </a> -->
        </span>
        <span v-else> (XXX) XXX - XXXX </span>
      </template>

      <!-- Column: kyc -->
      <template #cell(kycStatus)="data">
        <b-badge
          pill
          :variant="data.item.kycStatus == 'Approved' ? 'light-primary' : 'light-warning'"
          class="text-capitalize active-status"
        >
          {{data.item.kycStatus}}
          <!-- {{ data.item.kycDocs }} -->
          
          <small
            v-for="doc in data.item.kycDocs"
            :key="doc.docFileID"
            style="margin: 5px"
            class="cursor-pointer"
            @click="downloadFile(doc.url, doc.docName)"
          >
            <feather-icon
              class=""
              icon="DownloadIcon"
              stroke="#82868b"
              fill="#82868b"
              size="15"
            />
          </small>
        </b-badge>
      </template>

      <template #cell(details)="data">
        <b-button
          v-if="data.item.isKycUpdateAllowed == true && data.item.kycStatus == 'Under Review'"
          size="md"
          variant="outline-primary rounded-pill"
          class=""
          @click="updateClientData = data.item"
          v-b-modal.modal-update-status
        > Approve </b-button>
      </template>
      
      <!-- Column: Status -->
      <template #cell(subscriptionStatus)="data">
        <b-badge
          pill
          :variant="data.item.subscriptionStatus == 'active' ? 'light-success' : 'light-warning'"
          class="text-capitalize active-status"
        >
          {{data.item.subscriptionStatus}}
        </b-badge>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-center flex-wrap pt-0">
      <!-- pagination -->
      <div class="w-100">
        <b-pagination 
          v-model="currentPage"
          :total-rows="clientData.length"
          :per-page="parPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ArrowLeftIcon" size="18" class="mr-1" /> Previous
          </template>
          <template #next-text>
            <span>Next</span> <feather-icon icon="ArrowRightIcon" size="18" class="ml-1"/> 
          </template>
        </b-pagination >
      </div>
    </b-card-body>

    <b-modal size="sm" id="modal-update-status" title="BootstrapVue" centered ref="updateKYCStatusModal">
      <template #modal-header="">
        <div class="d-flex align-items-center">
          <h2 class="mb-0">Update KYC Status</h2>
        </div>
      </template>
      <div>
        <p>Confirm to proceed?</p>
      </div>
      <template #modal-footer="{ cancel }">
        <b-button size="md" class="cancel" @click="cancel()"> Cancel </b-button>
        <b-button size="md" variant="primary" @click="updateKYCStatus(updateClientData.subscriptionID)"> Proceed </b-button>
      </template>
    </b-modal>
    <add-client-model />
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ref, inject, onMounted } from '@vue/composition-api'
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from 'vue'
import AddClientModel from '../AddClientModel.vue';

export default {
  components: {
    AddClientModel
  },
  setup() {
    const $http = inject("$http");
    const fields = [
    {
        key: "createAt",
        label: "Date",
        sortable: true,
      },
      {
        key: "fullName",
        label: "Full Name",
        sortable: true,
      },
      {
        key: "email",
        label: "Email",
        sortable: true,
      },
      {
        key: "phoneFull",
        label: "Phone",
        sortable: true,
        class: "phone-number-td",
      },
      {
        key: "amountPaid",
        label: "Amount Paid",
        sortable: true,
      },
      {
        key: "subscriptionStatus",
        label: "Subscription",
        sortable: true,
      },
      {
        key: "kycStatus",
        label: "KYC Info",
      },
      {
        key: "details",
        label: "Details",
      },
      {
        key: "packageName",
        label: "Package",
        sortable: true,
      },
    ];

    const parPage = 20;
    const currentPage = ref(1);
    const sortByAscDesc = ref("asc");
    const sortBy = ref("");
    const filter = ref("");
    const sortDesc = ref(false);

    // const clientData = computed(() => store.getters["KYCSignUp/getUsers"]);
    const updateKYCStatusModal = ref(null);
    const updateClientData = ref(null);
    const clientData = ref([]);
    const sortChanged = (e) => {
      console.log(e.sortBy, "e.sortBy");
      sortBy.value = e.sortBy;
      if (e.sortDesc == true) {
        sortByAscDesc.value = "desc";
      } else {
        sortByAscDesc.value = "asc";
      }
    };

    const downloadFile = (file, name) => {
      const downloadLink = document.createElement("a");
      const type = file.split(";")[0].split("/")[1];
      downloadLink.href = file;
      const fileName = name + "." + type;
      downloadLink.download = fileName;
      downloadLink.click();
    };

    const getDate = (val) => {
      const date = new Date(val * 1000);
      return moment(date).format('DD-MM-YYYY')
    }

    const getClientData = async () => {
      await $http
        .get(`/advisor/clients`)
        .then((response) => {
          clientData.value = response.data?.clients
        })
        .catch((error) => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: `Error`,
              icon: "AlertCircleIcon",
              variant: "danger",
              text: error.response,
              closeOnClick: false,
              timeout: false,
            },
          });
        });
    };

    const updateKYCStatus = async (id) => {
      const payload = {
        subscriptionID: id,
        kycStatus: "approved"
      }
      
      await $http
        .put(`/advisor/clients/kyc/update`, payload)
        .then((response) => {
          if(response.status == 204) {
            updateKYCStatusModal.value.hide()
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: `Success`,
                icon: "AlertCircleIcon",
                variant: "success",
                text:'KYC updated successfully!',
                closeOnClick: false,
                timeout: false,
              },
            });
          } else {
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: `Error`,
                icon: "AlertCircleIcon",
                variant: "danger",
                text: "Something want to wrong",
                closeOnClick: false,
                timeout: false,
              },
            });
          }
        })
        .catch((error) => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: `Error`,
              icon: "AlertCircleIcon",
              variant: "danger",
              text: error.response,
              closeOnClick: false,
              timeout: false,
            },
          });
        });
    }

    onMounted(async () => {
      await getClientData();
    });


    return {
      sortByAscDesc,
      sortDesc,
      clientData,
      sortChanged,
      fields,
      filter,
      currentPage,
      parPage,
      sortBy,
      downloadFile,
      getDate,
      updateClientData,
      updateKYCStatus,
      updateKYCStatusModal
    };
  },
};
/* eslint-disable global-require */
</script>