<template>
    <div>
      <b-modal id="bv-modal-example" size="xl" class="w-100 bg-dark add-client" ref="addClientModal" @hide="formSubmitted = false, onModalClose()">
        <template #modal-title> Add New Client </template>

        <validation-observer ref="simpleRules">
          <div class="d-block">
            <div class="modal-body">
              <form>
                <!-- <b-row class="m-0"> -->
                <b-row class="m-0" v-for="(data, index) in formDataList" :key="index">
                  <b-col sm="12" md="2">
                    <InputField
                      :id="'First Name'"
                      :placeholder="'First Name'"
                      :describedby="'first-name-feedback'"
                      :label="'First Name'"
                      :name="'First Name'"
                      :rules="{ required: true }"
                      :modelValue="data.firstName"
                      @input="(val) => (data.firstName = val)"
                      :showValidationFeedback="formSubmitted"
                      :showValidationFeedbackmes="formSubmitted"
                    />
                  </b-col>

                  <b-col sm="12" md="2">
                    <InputField
                      :id="'Last Name'"
                      :placeholder="'Last Name'"
                      :describedby="'last-name-feedback'"
                      :label="'Last Name'"
                      :name="'Last Name'"
                      :rules="{ required: true }"
                      :modelValue="data.lastName"
                      @input="(val) => (data.lastName = val)"
                      :showValidationFeedback="formSubmitted"
                      :showValidationFeedbackmes="formSubmitted"
                    />
                  </b-col>

                  <b-col sm="12" md="auto">
                    <b-form-group
                      label="Country Code "
                      label-for="phone"
                    >
                      <div class="">
                        <vue-country-code
                          id="countryCode"
                          class="w-100"
                          :enabledFlags="true"
                          :enabledCountryCode="true"
                          :disabledFetchingCountry="true"
                          defaultCountry="IN"
                          @onSelect="(val) => (data.phoneCountryCode = val.dialCode)"
                          :preferredCountries="['vn', 'us', 'gb']"
                        >
                        </vue-country-code>
                      </div>
                    </b-form-group>
                  </b-col>

                  <b-col sm="12" md="2">
                    <InputField
                      :id="'phone'"
                      :placeholder="'Phone No'"
                      :describedby="'phone-feedback'"
                      :type="'number'"
                      :label="'Phone No.'"
                      :name="'phone'"
                      :rules="{ required: true }"
                      :modelValue="data.phone"
                      @input="(val) => (data.phone = val)"
                      :showValidationFeedback="formSubmitted"
                      :showValidationFeedbackmes="formSubmitted"
                    />
                  </b-col>

                  <b-col sm="12" md="2">
                    <SelectField
                      :id="'Subscription'"
                      :placeholder="''"
                      :describedby="'Subscription-feedback'"
                      :label="'Subscription Package'"
                      :name="'Subscription'"
                      :valueField="'id'"
                      :textField="'text'"
                      :rules="{ required: true }"
                      :modelValue="data.packageID"
                      @input="(val) => (data.packageID = val)"
                      :options="recommendationPackageOptions"
                      :showValidationFeedback="formSubmitted"
                      :showValidationFeedbackmes="formSubmitted"
                    />
                  </b-col>

                  <b-col sm="12" md="2">
                    <DateField
                      :id="'date' + index"
                      :placeholder="''"
                      :describedby="'date-feedback' + index"
                      :label="'Choose a Date'"
                      :name="'Date'"
                      :rules="{ required: true }"
                      :modelValue="data.subscriptionExpiry"
                      @input="(val) => (data.subscriptionExpiry = val)"
                      :showValidationFeedback="formSubmitted"
                      :showValidationFeedbackmes="formSubmitted"
                    />
                  </b-col>
                </b-row>
                <b-col sm="12" md="auto">
                  <b-button @click="addMore()">Add More</b-button>
                </b-col>
              </form>
            </div>
          </div>
        </validation-observer>
        <template #modal-footer="{ cancel }">
            <b-button size="lg" class="cancel" @click="cancel()"> Cancel </b-button>
            <b-button size="lg" class="submit" @click="saveClient()"> Submit </b-button>
          </template>
      </b-modal>
    </div>
</template>



<script>
import { ref, watch, onMounted, inject, reactive } from "@vue/composition-api";
import InputField from "@/views/components/form/InputField.vue";
import SelectField from "@/views/components/form/SelectField.vue";
import DateField from "@/views/components/form/DateField.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { v4 as uuidv4 } from 'uuid';
import Vue from 'vue'
import VueCountryCode from "vue-country-code";

/* eslint-disable global-require */
export default {
  components: {
    SelectField,
    InputField,
    DateField,
    VueCountryCode
  },
  setup(props) {
    const $http = inject("$http");

    const addClientModal = ref(null);
    const simpleRules = ref("");
    const formSubmitted= ref(false)

    const recommendationPackageOptions= reactive([
      { id: null, text: "please select a package" },
    ])

    const createNewFormData = () => ({
      firstName: "",
      lastName: "",
      phoneCountryCode: "",
      phone: "",
      packageID: null,
      subscriptionExpiry: "",
    });
    const formDataList = reactive([createNewFormData()]);
    const addMore = () => {
      formDataList.push({
        firstName: "",
        lastName: "",
        phoneCountryCode: "",
        phone: "",
        packageID: null,
        subscriptionExpiry: "",
      });
    };

    const onModalClose = () => {
      formDataList.splice(0, formDataList.length, createNewFormData());
    }

    const saveClient = async () => {
      formSubmitted.value =true
      simpleRules.value.validate().then(async (success) => {
        if (success) {
          console.log(formDataList);
          await $http
            .post(`/advisor/clients`, formDataList)
            .then((response) => {
              console.log('clients response -->', response);
              if(response.status == 204) {
                addClientModal.value.hide()
                Vue.$toast({
                  component: ToastificationContent,
                  props: {
                    title: `Success`,
                    icon: "AlertCircleIcon",
                    variant: "success",
                    text:'Client added successfully!',
                    closeOnClick: false,
                    timeout: false,
                  },
                });
              } else {
                Vue.$toast({
                  component: ToastificationContent,
                  props: {
                    title: `Error`,
                    icon: "AlertCircleIcon",
                    variant: "danger",
                    text: "Something want to wrong",
                    closeOnClick: false,
                    timeout: false,
                  },
                });
              }
            })
            .catch((error) => {
              formSubmitted.value =false
              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: `Error`,
                  icon: "AlertCircleIcon",
                  variant: "danger",
                  text: error.response,
                  closeOnClick: false,
                  timeout: false,
                },
              });
            });
        }
      })
    }

    const getPackage = async () => {
      await $http
        .get(`/advisor/packages`)
        .then((response) => {
          response.data?.packages.map(el => {
            recommendationPackageOptions.push( {id: el.uuid, text: el.title})
          })
        })
    };
    onMounted(async () => {
      await getPackage()
      formSubmitted.value = false
    });

    return {
      addClientModal,
      recommendationPackageOptions,
      formSubmitted,
      simpleRules,
      onModalClose,
      saveClient,
      formDataList,
      addMore
    };
  },
};
</script>

<style>
  .modal-footer .submit {
    background: #2563eb !important;
  }
  .modal-footer .cancel {
    background: #c4c4c4 !important;
  }
  .modal-body {
    padding: 0.8rem 0.4rem !important;
  }
  .form-control.is-valid,
  .form-control.is-valid:focus {
    background-image: none;
    border-color: #d8d6de;
    box-shadow: none;
  }
</style>